<template>
  <div>
    <v-layout align-center>
      <strong v-if="mtrModels.length > 0" >{{mtrModels.length}} modelo(s)</strong>
      <strong v-else>Nenhum modelo cadastrado</strong>
      <v-spacer/>
      <mtr-models-form-dialog
        :itemToEdit="mtrModelSelected"/>
    </v-layout>

    <v-layout>
      <v-flex xs12>
        <v-expansion-panel
          v-model="panel"
        >
          <v-expansion-panel-content v-for="(mtrModel, i) in mtrModels" :key="i">
            <template v-slot:header>
              <v-layout>
                <v-flex xs11>
                  <div>
                    <span>{{mtrModel.name}}</span>
                  </div>
                  <div>
                    <span class="caption">{{mtrModel.name}}</span>
                  </div>
                </v-flex>
                <v-flex xs1>
                  <v-menu
                    bottom
                    left
                    open-on-hover
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-tile
                        @click="editItem(mtrModel)">
                        <v-list-tile-title>
                          Editar Modelo
                        </v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-flex>
              </v-layout>

            </template>
            <v-divider/>

            <v-card>
              <v-card-text>

                <v-layout row align-center>
                  <v-flex xs5>
                    <h2 class="title">Transportador</h2>

                    <v-card
                      class="mb-4"
                      color="white"
                    >
                      <v-card-text>
                        <v-layout align-center>
                          <v-icon class="mr-2">mdi-truck-delivery</v-icon>
                          <v-layout class="text" column>
                            {{mtrModel.transporter.name}}
                            <span class="grey--text">
                              {{ mtrModel.transporter.cpf_cnpj.length === 14 ? 'CNPJ' : 'CPF' }}:&nbsp;
                              {{ formatCpfCnpj(mtrModel.transporter.cpf_cnpj) }}
                            </span>
                          </v-layout>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <v-flex class="text-xs-center" xs2>
                    <img
                      class="my-3 transport-arrow"
                      src="@/assets/transport-arrow.svg"
                      alt="Ícone de Seta"
                      width="80px"
                    />
                  </v-flex>

                  <v-flex xs5>
                    <h2 class="title">Destinador</h2>

                    <v-card
                      class="mb-4"
                      color="white"
                    >
                      <v-card-text>
                        <v-layout align-center>
                          <v-icon class="mr-2">mdi-delete-variant</v-icon>
                          <v-layout class="text" column>
                            {{mtrModel.receiver.name}}
                            <span class="grey--text">
                              {{ mtrModel.receiver.cpf_cnpj.length === 14 ? 'CNPJ' : 'CPF' }} :&nbsp;
                              {{ formatCpfCnpj(mtrModel.receiver.cpf_cnpj) }}
                            </span>
                          </v-layout>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>


              </v-card-text>
            </v-card>

            <v-divider/>
            <div v-for="(residue, index) in mtrModel.residues" :key="index">
              <v-layout
                class="px-3 py-2"
                align-center
              >
                <v-flex xs1>
                  <v-chip label>{{residue.residue.code}}</v-chip>
                </v-flex>
                <v-flex xs9>
                  {{residue.residue.name}}
                </v-flex>
                <v-flex class="text-xs-right" xs2>
                  {{residue.physical_state}}
                </v-flex>
              </v-layout>
              <v-layout
                class="px-3 py-3"
                grey lighten-3
              >
                <v-flex xs12>
                  <span>Classe: <strong>{{residue.residue_class}}</strong></span>
                  <span
                    class="pl-2"
                  >
                    Tipo de Armazenamento: <strong>{{residue.packaging}}</strong>
                  </span>
                  <span
                    class="pl-2"
                  >
                    Tecnologia: <strong>{{residue.disposal_technology}}</strong>
                  </span>
                </v-flex>
              </v-layout>
              <v-divider/>
            </div>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>

  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import masks from '@/utils/masks';
import validationRules from '@/mixins/validation-rules';
import MtrModelsFormDialog from '@/components/person/mtrModels/MtrModelsFormDialog';
import formatCpfCnpj from '@/utils/formatCpfCnpj';

export default {
  name: 'Person',
  components: { MtrModelsFormDialog },
  data() {
    return {
      panel: [],
      mtrModelSelected: {},
      formatCpfCnpj,
      isEmpty,
      masks,
      validationRules,
    };
  },
  methods: {
    editItem(mtrModel) {
      this.mtrModelSelected = { ...mtrModel };
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/currentUser'];
    },
    person() {
      return this.$store.getters['person/person'];
    },
    mtrModels() {
      return this.$store.getters['person/mtr_models'];
    },
  },
};
</script>
