var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: { dark: "", color: "green darken-2" },
          on: {
            click: function ($event) {
              _vm.dialog = true
            },
          },
        },
        [
          _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-file-document")]),
          _c("span", { staticClass: "ml-2" }, [_vm._v("Criar modelo")]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px", persistent: "" },
          model: {
            value: _vm.addResidueDialog,
            callback: function ($$v) {
              _vm.addResidueDialog = $$v
            },
            expression: "addResidueDialog",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "success", dark: "", flat: "" } },
                    [
                      _c("span", { staticClass: "headline" }, [
                        _vm._v("Adicionar Resíduo"),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.addResidueDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-autocomplete", {
                                    ref: "residueInput",
                                    attrs: {
                                      items: _vm.fetchedResidues,
                                      "item-text": "name",
                                      "return-object": "",
                                      loading: _vm.isFetchingResidues,
                                      "no-data-text":
                                        "Nenhum resíduo encontrado",
                                      "hide-no-data":
                                        !_vm.residuesSearch || _vm.isUserTyping,
                                      "no-filter": "",
                                      "search-input": _vm.residuesSearch,
                                      label:
                                        "Buscar resíduo por: Código/Palavra",
                                      rules: [_vm.rules.required],
                                      "prepend-icon":
                                        "mdi-feature-search-outline",
                                      "menu-props": { maxWidth: "600" },
                                    },
                                    on: {
                                      "update:searchInput": function ($event) {
                                        _vm.residuesSearch = $event
                                      },
                                      "update:search-input": function ($event) {
                                        _vm.residuesSearch = $event
                                      },
                                      input: function ($event) {
                                        return _vm.onChangeSelectedResidue()
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "append-outer",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  top: "",
                                                  "max-width": "300",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g({}, on),
                                                          [
                                                            _vm._v(
                                                              "\n                            mdi-information-outline\n                          "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                            Listagem de Código e descrição dos resíduos de acordo\n                             com a Lista Brasileira de Resíduos Sólidos do IBAMA\n                            (Instrução Normativa Nº 13, de 18 de dezembro de 2012)\n                          "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c("v-list-tile-title", [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(data.item.code) +
                                                      " - " +
                                                      _vm._s(data.item.name) +
                                                      "\n                        "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.editedItem.residue,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editedItem, "residue", $$v)
                                      },
                                      expression: "editedItem.residue",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md6: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.classes,
                                      label: "Classes",
                                      rules: [_vm.rules.required],
                                      placeholder: _vm.classFieldPlaceholder,
                                      disabled: _vm.isEmpty(
                                        _vm.editedItem.residue
                                      ),
                                    },
                                    model: {
                                      value: _vm.editedItem.residueClass,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editedItem,
                                          "residueClass",
                                          $$v
                                        )
                                      },
                                      expression: "editedItem.residueClass",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md6: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.physicalStates,
                                      "item-text": "name",
                                      "item-id": "id",
                                      label: "Estado físico",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.editedItem.physicalState,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editedItem,
                                          "physicalState",
                                          $$v
                                        )
                                      },
                                      expression: "editedItem.physicalState",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md6: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.packagings,
                                      label: "Acondicionamento",
                                      disabled: !_vm.editedItem.physicalState,
                                      placeholder:
                                        _vm.hasPhysicalStatePlaceHolder,
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.editedItem.packaging,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editedItem,
                                          "packaging",
                                          $$v
                                        )
                                      },
                                      expression: "editedItem.packaging",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md6: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.technologiesNames,
                                      label: "Tecnologia",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.editedItem.disposalTechnology,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editedItem,
                                          "disposalTechnology",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem.disposalTechnology",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "grey", flat: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.close.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n              Fechar\n            ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.addResidueInResidues()
                            },
                          },
                        },
                        [_vm._v("\n              Adicionar\n            ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "success", dark: "", flat: "" } },
            [
              _c("span", { staticClass: "headline" }, [
                _vm._v(
                  _vm._s(_vm.id === null ? "Novo Modelo" : "Editar Modelo")
                ),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper",
            {
              attrs: { "alt-labels": "" },
              model: {
                value: _vm.e1,
                callback: function ($$v) {
                  _vm.e1 = $$v
                },
                expression: "e1",
              },
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.e1 > 1, step: "1" } },
                    [_vm._v("Informações")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.e1 > 2, step: "2" } },
                    [_vm._v("Resíduos")]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Nome do modelo" },
                                        model: {
                                          value: _vm.mtrModelName,
                                          callback: function ($$v) {
                                            _vm.mtrModelName = $$v
                                          },
                                          expression: "mtrModelName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Descrição do modelo" },
                                        model: {
                                          value: _vm.mtrModelDescription,
                                          callback: function ($$v) {
                                            _vm.mtrModelDescription = $$v
                                          },
                                          expression: "mtrModelDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("p", [_vm._v("Transportador")]),
                              _c("MtrPersonForm", {
                                attrs: {
                                  title: "Informações do transportador",
                                  person: _vm.transporter,
                                  profile: "transporter",
                                },
                                on: {
                                  "update:person": function ($event) {
                                    _vm.transporter = $event
                                  },
                                },
                              }),
                              _c("p", [_vm._v("Destinador")]),
                              _c("MtrPersonForm", {
                                attrs: {
                                  title: "Informações do destinador",
                                  person: _vm.receiver,
                                  profile: "receiver",
                                },
                                on: {
                                  "update:person": function ($event) {
                                    _vm.receiver = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "red", flat: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.close()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Cancelar\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "green darken-2",
                                    dark: "",
                                    disabled: !_vm.step1Valid(),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.e1 = 2
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Próximo\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        _vm._l(_vm.residues, function (mtrResidue, index) {
                          return _c(
                            "v-card",
                            {
                              key: index,
                              staticClass: "item mb-3",
                              attrs: { color: "grey lighten-4" },
                            },
                            [
                              _c(
                                "v-toolbar",
                                { attrs: { color: "white", flat: "" } },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { label: "", small: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(mtrResidue.residue.code) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                  _c("h2", { staticClass: "subheading" }, [
                                    _vm._v(_vm._s(mtrResidue.residue.name)),
                                  ]),
                                  _c("v-spacer"),
                                  _c(
                                    "span",
                                    { staticClass: "subheading grey--text" },
                                    [_vm._v(_vm._s(mtrResidue.physicalState))]
                                  ),
                                  _c(
                                    "v-menu",
                                    { attrs: { "offset-y": "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            slot: "activator",
                                            fab: "",
                                            flat: "",
                                          },
                                          slot: "activator",
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-dots-horizontal"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-tile",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteItem(index)
                                                },
                                              },
                                            },
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v("Remover resíduo"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider", { attrs: { light: "" } }),
                              _c(
                                "v-card-text",
                                { staticClass: "grey--text text--darken-2" },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        "justify-space-around": "",
                                      },
                                    },
                                    [
                                      _c("v-flex", [
                                        _vm._v(
                                          "\n                      Classe:\n                      "
                                        ),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              mtrResidue.residue_class ||
                                                mtrResidue.residueClass
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("v-flex", [
                                        _vm._v(
                                          "\n                      Acondicionamento:\n                      "
                                        ),
                                        _c("strong", [
                                          _vm._v(_vm._s(mtrResidue.packaging)),
                                        ]),
                                      ]),
                                      _c("v-flex", [
                                        _vm._v(
                                          "\n                      Tecnologia:\n                      "
                                        ),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              mtrResidue.disposal_technology ||
                                                mtrResidue.disposalTechnology
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.addResidueDialog = true
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-plus-circle-outline"),
                              ]),
                              _vm._v(
                                "\n                Adicionar resíduo\n              "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "green darken-2",
                                disabled: _vm.loadingMtrModel,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 1
                                },
                              },
                            },
                            [_vm._v("\n                Voltar\n              ")]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "red darken-2",
                                disabled: _vm.loadingMtrModel,
                                flat: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.close()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                Cancelar\n              "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "green darken-2",
                                dark: "",
                                loading: _vm.loadingMtrModel,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.save()
                                },
                              },
                            },
                            [_vm._v("\n              Salvar\n              ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }