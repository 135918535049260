<template>
  <div>
    <v-btn
      dark
      color="green darken-2"
      @click="dialog = true"
    >
      <v-icon small>mdi-file-document</v-icon> <span class="ml-2">Criar modelo</span>
    </v-btn>
    <v-dialog
      v-model="addResidueDialog" max-width="700px" persistent>

      <v-form ref="form" lazy-validation v-model="valid">
        <v-card>
          <v-toolbar color="success" dark flat>
            <span class="headline">Adicionar Resíduo</span>
            <v-spacer />
            <v-btn flat icon @click="addResidueDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-autocomplete
                    ref="residueInput"
                    v-model="editedItem.residue"
                    :items="fetchedResidues"
                    item-text="name"
                    return-object
                    :loading="isFetchingResidues"
                    no-data-text="Nenhum resíduo encontrado"
                    :hide-no-data="!residuesSearch || isUserTyping"
                    no-filter
                    :search-input.sync="residuesSearch"
                    label="Buscar resíduo por: Código/Palavra"
                    :rules="[rules.required]"
                    prepend-icon="mdi-feature-search-outline"
                    :menu-props="{maxWidth:'600'}"
                    @input="onChangeSelectedResidue()">
                    <template v-slot:append-outer>
                      <v-tooltip
                        top
                        max-width="300">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>

                        <span>
                            Listagem de Código e descrição dos resíduos de acordo
                             com a Lista Brasileira de Resíduos Sólidos do IBAMA
                            (Instrução Normativa Nº 13, de 18 de dezembro de 2012)
                          </span>
                        <!-- TODO: Aplicar texto legal para resíduos -->
                      </v-tooltip>
                    </template>

                    <template
                      v-slot:item="data">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{data.item.code}} - {{data.item.name}}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex xs12 md6>
                  <v-select
                    :items="classes"
                    v-model="editedItem.residueClass"
                    label="Classes"
                    :rules="[rules.required]"
                    :placeholder="classFieldPlaceholder"
                    :disabled="isEmpty(editedItem.residue)"
                  />
                </v-flex>

                <v-flex xs12 md6>
                  <v-select
                    :items="physicalStates"
                    item-text="name"
                    item-id="id"
                    v-model="editedItem.physicalState"
                    label="Estado físico"
                    :rules="[rules.required]"
                  />
                </v-flex>

                <v-flex xs12 md6>
                  <v-select
                    :items="packagings"
                    v-model="editedItem.packaging"
                    label="Acondicionamento"
                    :disabled="!editedItem.physicalState"
                    :placeholder="hasPhysicalStatePlaceHolder"
                    :rules="[rules.required]"
                  />
                </v-flex>

                <v-flex xs12 md6>
                  <v-select
                    :items="technologiesNames"
                    v-model="editedItem.disposalTechnology"
                    label="Tecnologia"
                    :rules="[rules.required]"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey"
              @click.native="close"
              flat
            >
              Fechar
            </v-btn>
            <v-btn
              color="primary"
              @click.native="addResidueInResidues()"
            >
              Adicionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      width="800"
      persistent
      v-model="dialog">

      <v-toolbar color="success" dark flat>
        <span class="headline">{{ id === null ? 'Novo Modelo' : 'Editar Modelo' }}</span>
        <v-spacer />
        <v-btn flat icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-stepper
        alt-labels
        v-model="e1"
      >
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">Informações</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">Resíduos</v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">

            <v-card>

              <v-card-text>
                <v-layout column>
                  <v-flex xs12>
                    <v-text-field
                      v-model="mtrModelName"
                      label="Nome do modelo"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="mtrModelDescription"
                      label="Descrição do modelo"
                    />
                  </v-flex>
                </v-layout>
                <p>Transportador</p>

                <MtrPersonForm
                  title="Informações do transportador"
                  :person.sync="transporter"
                  profile='transporter'
                />

                <p>Destinador</p>
                <MtrPersonForm
                  title="Informações do destinador"
                  :person.sync="receiver"
                  profile='receiver'
                />

              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="red"
                  @click="close()"
                  flat
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="green darken-2"
                  dark
                  :disabled="!step1Valid()"
                  @click="e1 = 2"
                >
                  Próximo
                </v-btn>
              </v-card-actions>

            </v-card>

          </v-stepper-content>

          <v-stepper-content step="2">

            <v-layout column>
              <v-card
                color="grey lighten-4"
                class="item mb-3"
                v-for="(mtrResidue, index) in residues"
                :key="index"
              >
                <v-toolbar color="white" flat>
                  <v-chip
                    class="mr-3"
                    label
                    small
                  >
                    {{ mtrResidue.residue.code }}
                  </v-chip>
                  <h2 class="subheading">{{ mtrResidue.residue.name }}</h2>

                  <v-spacer />

                  <span class="subheading grey--text">{{ mtrResidue.physicalState }}</span>

                  <v-menu offset-y>
                    <v-btn
                      slot="activator"
                      fab
                      flat
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>

                    <v-list>
<!--                      <v-list-tile @click="editItem(index, mtrResidue)">-->
<!--                        <v-list-tile-title>Editar resíduo</v-list-tile-title>-->
<!--                      </v-list-tile>-->
                      <v-list-tile @click="deleteItem(index)">
                        <v-list-tile-title>Remover resíduo</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-toolbar>

                <v-divider light />

                <v-card-text class="grey--text text--darken-2">
                  <v-layout row justify-space-around>
                    <v-flex>
                      Classe:
                      <strong>{{ mtrResidue.residue_class || mtrResidue.residueClass }}</strong>
                    </v-flex>
                    <v-flex>
                      Acondicionamento:
                      <strong>{{ mtrResidue.packaging }}</strong>
                    </v-flex>
                    <v-flex>
                      Tecnologia:
                      <strong>{{ mtrResidue.disposal_technology || mtrResidue.disposalTechnology }}</strong>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-layout>

            <v-layout justify-center>
              <v-btn
                color="primary"
                flat
                @click="addResidueDialog = true"
              >
                <v-icon left>mdi-plus-circle-outline</v-icon>
                Adicionar resíduo
              </v-btn>
            </v-layout>

            <v-layout>
              <v-btn
                dark
                color="green darken-2"
                @click="e1 = 1"
                :disabled="loadingMtrModel"
              >
                Voltar
              </v-btn>
              <v-spacer/>
              <v-btn
                @click="close()"
                color="red darken-2"
                :disabled="loadingMtrModel"
                flat
              >
                Cancelar
              </v-btn>
              <v-btn
                color="green darken-2"
                dark
                :loading="loadingMtrModel"
                @click.native="save()"
              >
              Salvar
              </v-btn>
            </v-layout>

          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </v-dialog>
  </div>

</template>
<script>
import validationRules from '@/mixins/validation-rules';
import { isEmpty, get, find, omit } from 'lodash';
import mtrService from '@/services/mtr-service';
import mtrModelService from '@/services/mtr-model-service';
import { mapActions, mapGetters } from 'vuex';
import { VMoney } from 'v-money';
import MtrPersonForm from '@/components/mtr/MtrPersonForm.vue';
import formatCpfCnpj from '@/utils/formatCpfCnpj';


export default {
  name: 'MtrModelsFormDialog',
  directives: { money: VMoney },
  components: { MtrPersonForm },
  props: ['itemToEdit'],
  beforeMount() {
    this.fetchMeasureUnities();
    this.fetchPhysicalStates();
    this.fetchPackagings();
    this.fetchTechnologies();
  },
  mounted() {
    this.$store.dispatch('person/fetchMtrModels');
  },
  data() {
    return {
      id: null,
      dialog: false,
      e1: 0,
      formatCpfCnpj,
      receiver: {},
      transporter: {},
      headers: [
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Descrição',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Transportador',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Destinador',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Ações',
          align: 'center',
          sortable: false,
        },
      ],
      isEmpty,
      classes: [],
      addResidueDialog: false,
      residues: [],
      rules: validationRules,
      fetchedResidues: [],
      isFetchingResidues: false,
      valid: false,
      residuesSearch: null,
      mtrModelName: '',
      mtrModelDescription: '',
      editedItem: {
        residue: {},
        physicalState: '',
        residueClass: '',
        packaging: '',
        quantity: '',
        measureUnity: '',
        disposalTechnology: '',
      },
      loadingMtrModel: false,
      money: {
        decimal: '.',
        thousands: '.',
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchMeasureUnities: 'mtr/fetchMeasureUnities',
      fetchPhysicalStates: 'mtr/fetchPhysicalStates',
      fetchPackagings: 'mtr/fetchPackagings',
      fetchTechnologies: 'mtr/fetchTechnologies',
    }),
    step1Valid() {
      // return !this.isEmpty(this.mtrModelName) &&
      //   !this.isEmpty(this.mtrModelDescription) &&
      //   !this.isEmpty(this.receiver) &&
      //   !this.isEmpty(this.transporter);
      return true;
    },

    destroy(id) {
      mtrModelService
        .destroy(id)
        .then(() => {
          this.$store.dispatch('person/fetchMtrModels');
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Modelo removido com Sucesso',
            messageIcon: 'mdi-alert-outline',
          });
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
    save() {
      const payload = {
        id: this.id,
        name: this.mtrModelName,
        description: this.mtrModelDescription,
        receiver_id: this.receiver.id,
        transporter_id: this.transporter.id,
        mtr_model_residues: this.transformResidues(),
      };

      this.loadingMtrModel = true;

      if (this.id !== null) {
        mtrModelService.update(payload)
          .then(() => {
            this.$store.dispatch('person/fetchMtrModels');
            this.$store.dispatch('application/toggleSnackbar', {
              show: true,
              timeout: 6000,
              color: 'success',
              message: 'Modelo salvo com Sucesso',
              messageIcon: 'mdi-alert-outline',
            });
            // usado pra fechar o modal e reiniciar o form
            this.close();
          })
          .catch((err) => {
            this.$store.dispatch('application/toggleSnackbar', {
              show: true,
              timeout: 6000,
              color: 'error',
              message: err.response.data.join(', '),
              messageIcon: 'mdi-alert-outline',
            });
          })
          .finally(() => {
            this.loadingMtrModel = false;
          });
      } else {
        mtrModelService.create(payload)
          .then(() => {
            this.$store.dispatch('person/fetchMtrModels');
            this.$store.dispatch('application/toggleSnackbar', {
              show: true,
              timeout: 6000,
              color: 'success',
              message: 'Modelo salvo com Sucesso',
              messageIcon: 'mdi-alert-outline',
            });
            // usado pra fechar o modal e reiniciar o form
            this.close();
          })
          .catch((err) => {
            this.$store.dispatch('application/toggleSnackbar', {
              show: true,
              timeout: 6000,
              color: 'error',
              message: err.response.data.join(', '),
              messageIcon: 'mdi-alert-outline',
            });
          })
          .finally(() => {
            this.loadingMtrModel = false;
          });
      }
    },
    transformResidues() {
      return this.residues.map(residue => ({
        disposal_technology: residue.disposalTechnology,
        packaging: residue.packaging,
        physical_state: residue.physicalState,
        residue_class: residue.residueClass,
        residue_data: omit(residue.residue, 'residue_classes'),
      }));
    },
    addResidueInResidues() {
      this.residues.push(this.editedItem);
      this.addResidueDialog = false;
      this.resetEditedItem();
    },
    onChangeSelectedResidue() {
      this.editedItem = {
        ...this.editedItem,
        physicalState: '',
        residueClass: '',
        measureUnity: '',
        packaging: '',
        disposalTechnology: '',
      };

      if (!isEmpty(this.editedItem.residue)) {
        this.getEditedItemCompleteInfo();
      }
      this.$refs.residueInput.blur();
    },
    close() {
      this.resetEditedItem();
      this.mtrModelName = '';
      this.mtrModelDescription = '';
      this.receiver = {};
      this.transporter = {};
      this.e1 = 1;
      this.id = null;
      this.dialog = false;
      this.isFetchingResidues = false;
      this.addResidueDialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
      this.residuesSearch = null;
      this.fetchedResidues = [];
    },
    resetEditedItem() {
      this.editedItem = {
        residue: {},
        disposalTechnology: '',
        physicalState: '',
        residueClass: '',
        packaging: '',
        quantity: '',
        measureUnity: '',
      };
    },
    fetchResidues(val) {
      if (val === get(this.editedItem, 'residue.description')) return;
      this.isFetchingResidues = true;

      mtrService.residuesList(val)
        .then(({ data }) => {
          this.fetchedResidues = data;
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isFetchingResidues = false;
        });
    },
    getEditedItemCompleteInfo() {
      const vm = this;
      mtrService.residuesList(this.editedItem.residue.code)
        .then((residues) => {
          vm.fetchedResidues = residues.data;
          const completeResidueInfo = find(vm.fetchedResidues, ['code', vm.editedItem.residue.code]);
          vm.editedItem.residue = {
            ...vm.editedItem.residue,
            residue_classes: completeResidueInfo.residue_classes,
          };
          vm.setResidueClasses(vm.editedItem.residue);
        });
    },
    setResidueClasses(residue) {
      this.classes = !isEmpty(residue) && !isEmpty(residue.residue_classes) ?
        residue.residue_classes.map(cl => cl.name) : [];
    },
    densitySufix(unit) {
      const densitySufixPerUnit = {
        'Metro Cúbico': 't/m³',
        Litro: 'g/cm³',
      };
      return densitySufixPerUnit[unit] || '';
    },
    deleteItem(index) {
      const removeItem = window.confirm('Você quer remover este item?'); // eslint-disable-line no-alert
      if (removeItem) {
        this.residues.splice(index, 1);
      }
    },
    editItem(index, mtrResidue) {
      this.editedIndex = index;
      this.editedItem = { ...mtrResidue };
      this.addResidueDialog = true;
      this.residuesSearch = null;
      this.fetchedResidues = [this.editedItem.residue];
      this.getEditedItemCompleteInfo();
    },
  },
  computed: {
    ...mapGetters({
      measureUnities: 'mtr/measureUnities',
      physicalStates: 'mtr/physicalStates',
      technologies: 'mtr/technologies',
    }),
    measureUnityNames() {
      return this.measureUnities.map(unity => unity.name);
    },

    technologiesNames() {
      return this.technologies.map(state => state.name);
    },

    dialogTitle() {
      return this.editedIndex === -1 ? 'Novo resíduo' : 'Editar resíduo';
    },

    hasPhysicalStatePlaceHolder() {
      return !this.editedItem.physicalState ? 'Selecione um estado físico' : '';
    },

    classFieldPlaceholder() {
      return !this.editedItem.residue ? 'Selecione um resíduo' : '';
    },

    packagings() {
      const { physicalState } = this.editedItem;

      if (!physicalState) {
        return [];
      }

      const physicalStateSelected = this.physicalStates
        .find(state => state.name === physicalState);

      if (!physicalStateSelected) {
        return [];
      }

      return physicalStateSelected.packagings.map(p => p.name);
    },

    hasDensity() {
      const measureUnitiesWithDensity = ['Metro Cúbico', 'Litro'];
      const { measureUnity } = this.editedItem;
      return measureUnity && measureUnitiesWithDensity.includes(measureUnity);
    },
    personId() {
      return this.$router.currentRoute.params.id;
    },
    mtrModels() {
      return this.$store.getters['person/mtr_models'];
    },
    office() {
      return this.$store.getters['person/office'];
    },
  },
  watch: {
    residuesSearch(val) {
      if (!val) return;
      clearTimeout(this.timeout);
      this.isUserTyping = true;
      this.timeout = setTimeout(() => {
        this.isUserTyping = false;
        this.fetchResidues(val);
      }, 500);
    },
    itemToEdit(item) {
      this.id = item.id;
      this.receiver = item.receiver;
      this.transporter = item.transporter;
      this.residues = Object.assign([], item.residues);
      this.mtrModelName = item.name;
      this.mtrModelDescription = item.description;

      this.dialog = true;
    },
  },
};
</script>
<style lang="scss">

  .v-stepper{
    padding: 20px 10px;

    .v-stepper__header{
      box-shadow: none;
      max-width: 400px;
      margin: 0 auto;
    }

    .v-stepper__content{
      padding: 0;
    }
  }

</style>
