var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "" } },
        [
          _vm.mtrModels.length > 0
            ? _c("strong", [
                _vm._v(_vm._s(_vm.mtrModels.length) + " modelo(s)"),
              ])
            : _c("strong", [_vm._v("Nenhum modelo cadastrado")]),
          _c("v-spacer"),
          _c("mtr-models-form-dialog", {
            attrs: { itemToEdit: _vm.mtrModelSelected },
          }),
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-expansion-panel",
                {
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                _vm._l(_vm.mtrModels, function (mtrModel, i) {
                  return _c(
                    "v-expansion-panel-content",
                    {
                      key: i,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "v-layout",
                                  [
                                    _c("v-flex", { attrs: { xs11: "" } }, [
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(_vm._s(mtrModel.name)),
                                        ]),
                                      ]),
                                      _c("div", [
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(_vm._s(mtrModel.name)),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs1: "" } },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              bottom: "",
                                              left: "",
                                              "open-on-hover": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: { icon: "" },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-dots-horizontal"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-tile",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editItem(
                                                          mtrModel
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-list-tile-title", [
                                                      _vm._v(
                                                        "\n                        Editar Modelo\n                      "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("v-divider"),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", "align-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs5: "" } },
                                    [
                                      _c("h2", { staticClass: "title" }, [
                                        _vm._v("Transportador"),
                                      ]),
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mb-4",
                                          attrs: { color: "white" },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { "align-center": "" },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "mdi-truck-delivery"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "text",
                                                      attrs: { column: "" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            mtrModel.transporter
                                                              .name
                                                          ) +
                                                          "\n                          "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "grey--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                mtrModel
                                                                  .transporter
                                                                  .cpf_cnpj
                                                                  .length === 14
                                                                  ? "CNPJ"
                                                                  : "CPF"
                                                              ) +
                                                              ": \n                            " +
                                                              _vm._s(
                                                                _vm.formatCpfCnpj(
                                                                  mtrModel
                                                                    .transporter
                                                                    .cpf_cnpj
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-xs-center",
                                      attrs: { xs2: "" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "my-3 transport-arrow",
                                        attrs: {
                                          src: require("@/assets/transport-arrow.svg"),
                                          alt: "Ícone de Seta",
                                          width: "80px",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs5: "" } },
                                    [
                                      _c("h2", { staticClass: "title" }, [
                                        _vm._v("Destinador"),
                                      ]),
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mb-4",
                                          attrs: { color: "white" },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { "align-center": "" },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "mdi-delete-variant"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "text",
                                                      attrs: { column: "" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            mtrModel.receiver
                                                              .name
                                                          ) +
                                                          "\n                          "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "grey--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                mtrModel
                                                                  .receiver
                                                                  .cpf_cnpj
                                                                  .length === 14
                                                                  ? "CNPJ"
                                                                  : "CPF"
                                                              ) +
                                                              " : \n                            " +
                                                              _vm._s(
                                                                _vm.formatCpfCnpj(
                                                                  mtrModel
                                                                    .receiver
                                                                    .cpf_cnpj
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm._l(mtrModel.residues, function (residue, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "v-layout",
                              {
                                staticClass: "px-3 py-2",
                                attrs: { "align-center": "" },
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs1: "" } },
                                  [
                                    _c("v-chip", { attrs: { label: "" } }, [
                                      _vm._v(_vm._s(residue.residue.code)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("v-flex", { attrs: { xs9: "" } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(residue.residue.name) +
                                      "\n              "
                                  ),
                                ]),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-xs-right",
                                    attrs: { xs2: "" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(residue.physical_state) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-layout",
                              {
                                staticClass: "px-3 py-3",
                                attrs: { grey: "", "lighten-3": "" },
                              },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("span", [
                                    _vm._v("Classe: "),
                                    _c("strong", [
                                      _vm._v(_vm._s(residue.residue_class)),
                                    ]),
                                  ]),
                                  _c("span", { staticClass: "pl-2" }, [
                                    _vm._v(
                                      "\n                  Tipo de Armazenamento: "
                                    ),
                                    _c("strong", [
                                      _vm._v(_vm._s(residue.packaging)),
                                    ]),
                                  ]),
                                  _c("span", { staticClass: "pl-2" }, [
                                    _vm._v("\n                  Tecnologia: "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(residue.disposal_technology)
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c("v-divider"),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }